import {get} from '../utils';
import {AxiosResponse} from 'axios';
import {API_BASE_URL_LOGIN_EDEKA_SERVICE} from '../constants';

export const fetchTermsOfService = (): Promise<AxiosResponse<string>> =>
  get(
    `${API_BASE_URL_LOGIN_EDEKA_SERVICE}/api/customer-service/v1/login/terms-of-service`
  );

export const fetchPrivacyPolicy = (): Promise<AxiosResponse<string>> =>
  get(
    `${API_BASE_URL_LOGIN_EDEKA_SERVICE}/api/customer-service/v1/login/privacy-policy`
  );
